<template>
  <div class="companySubaccount-container">
    <breadcrumb :BreadcrumbData="BreadcrumbData"></breadcrumb>
    <div class="companySubaccount-detail">
      <img
        class="detail-img"
        v-if="this.$route.query.shopLogo"
        :src="this.$route.query.shopLogo"
        alt="icon"
      />
      <span class="user-icon" v-else><i class="el-icon-user iconPeople"></i></span>
      <div>
        <div class="detail-title">
          店铺名称: {{ $route.query.shopName || "--"
          }}<el-button
            class="common-screen-btn"
            type="primary"
            @click="
              () => {
                downLoad($route.query.fileUrl, 'fileUrl');
              }
            "
            >下载店铺交接文档</el-button
          >
        </div>
        <!-- <span class="detail-item">所属公司: {{ $route.query.companyName || "--" }}</span> -->
        <span class="detail-item">所属平台: {{ $route.query.platformName || "--" }}</span>
        <span class="detail-item">所属类目: {{ $route.query.categoryName || "--" }}</span>
        <span class="detail-item">所属客户: {{ $route.query.customerName || "--" }}</span>
      </div>
    </div>
    <div class="common-screen-container">
      <div class="common-input-container">
        <span>子账号名称:</span>
        <el-input
          class="common-screen-input"
          placeholder="请输入"
          v-model="params.name"
        ></el-input>
      </div>
      <div class="common-input-container">
        <el-date-picker
          v-model="params.datePicker"
          type="daterange"
          range-separator="~"
          @change="commonFun"
          :clearable="false"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </div>
      <el-button class="common-screen-btn" type="primary" @click="() => subaccountListNew()">查 询</el-button>
      <el-button class="common-screen-btn" plain @click="() => resetBtn()" >重 置</el-button>
    </div>
    <!-- <el-button class="common-screen-btn" type="primary" @click="addEdit()">新建子账号</el-button> -->

    <div v-if="isAssign == 0" class="common-upload-container" style="margin: 0">
      <el-button class="common-screen-btn" type="primary" :loading="btnLoading">批量上传分配客服</el-button>
      <input v-if="!btnLoading" class="common-upload-input" @input=" (e) => { serviceUpload(e); }" type="file"/>
    </div>
    <el-button v-if="isAssign == 0" class="common-screen-btn" type="text" @click=" () => downLoad('', 'downLoad') " >子账号分配客服模板</el-button>
    <el-table class="common-table" :data="tableData">
      <el-table-column
        prop="subaccountName"
        label="子账号名称"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="subaccountPassword"
        label="子账号密码"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="phone"
        label="管理员手机号"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="serviceName"
        label="指派客服"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column label="服务时间" width="200px">
        <template slot-scope="scope">
          <span>{{
            scope.row.startTime && scope.row.endTime
              ? scope.row.startTime + " ~ " + scope.row.endTime
              : "--"
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="note"
        label="备注"
      >
        <template slot-scope="scope">
          <el-tooltip v-if="scope.row.note" class="item" effect="dark" :content="scope.row.note" placement="bottom">
            <div class="common-overflow-drop">{{scope.row.note}}</div>
          </el-tooltip>
          <div v-else>--</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="createName"
        label="创建人"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="createTime"
        :formatter="tableColumn"
        label="创建时间"
      ></el-table-column>
      <el-table-column label="操作" width="200" fixed="right">
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click="
              () => {
                $router.push({
                  path: '/receive-order/subaccount-list/service',
                  query: {
                    shopId: $route.query.shopId,
                    subaccountName: scope.row.subaccountName,
                    shopName: $route.query.shopName,
                    categoryName: $route.query.categoryName,
                    platformName: $route.query.platformName,
                    customerName: $route.query.customerName,
                    startTime: $route.query.startTime,
                    endTime: $route.query.endTime,
                    shopLogo: $route.query.shopLogo,
                    subId: scope.row.id,
                    fileUrl: $route.query.fileUrl
                  },
                });
              }"
            >客服分配情况</el-button>
          <el-button v-if="isAssign == 0" type="text" size="small" @click="() => addEdit(scope.row)">编辑</el-button>
          &nbsp;&nbsp;&nbsp;
          <el-popconfirm v-if="isAssign == 0"
            placement="top"
            title="确定删除吗？"
            @confirm="() => deleteSubaccount(scope.row.id)"
          >
            <el-button type="text" size="small" slot="reference">删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="(e) => pageFun(e, 'pageSize')"
      @current-change="(e) => pageFun(e)"
      style="text-align: center"
      :page-sizes="pagination.pageSizes"
      :page-size="params.pageSize"
      :current-page="params.pageNum"
      layout="total, prev, pager, next,sizes, jumper"
      :total="pagination.total"
    >
    </el-pagination>
    <el-dialog
      v-dialogDrag
      :title="!tableRowId ? '新建子账号' : '修改子账号'"
      :append-to-body="true"
      :visible.sync="centerDialogVisible"
      width="520px"
    >
      <div>
        <el-form :model="ruleFormAdd" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm" >
          <el-form-item label="子账号名称" prop="subaccountName">
            <el-input
              class="dialog-input"
              placeholder="请填写子账号名称"
              v-model="ruleFormAdd.subaccountName"
              maxLength="50"
            ></el-input>
          </el-form-item>
          <el-form-item label="子账号密码" prop="subaccountPassword">
            <el-input
              class="dialog-input"
              placeholder="请填写子账号密码"
              v-model="ruleFormAdd.subaccountPassword"
              maxLength="50"
            ></el-input>
          </el-form-item>
          <el-form-item label="管理员手机号" prop="phone">
            <el-input
              class="dialog-input"
              placeholder="请填写管理员手机号"
              v-model="ruleFormAdd.phone"
              maxLength="50"
            ></el-input>
          </el-form-item>
          <el-form-item label="服务时间" prop="datePicker">
            <el-date-picker
              v-model="ruleFormAdd.datePicker"
              class="dialog-input"
              type="daterange"
              :clearable="false"
              range-separator="~"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
             <!-- :picker-options="pickerOptions" -->
            </el-date-picker>
          </el-form-item>
          <el-form-item label="备注">
            <el-input
              type="textarea"
              class="dialog-input"
              placeholder="请填写备注"
              v-model="ruleFormAdd.note"
              maxLength="500"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="common-screen-btn" @click="centerDialogVisible = false"
          >取 消</el-button
        >
        <el-button
          class="common-screen-btn"
          type="primary"
          @click="() => submitForm('ruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      v-dialogDrag
      title="指派客服"
      :append-to-body="true"
      :visible.sync="assignVisible"
      width="520px"
    >
      <div>
        <el-form
          :model="assignForm"
          :rules="assignRules"
          ref="assignForm"
          label-width="130px"
          class="demo-ruleForm"
        >
          <el-form-item label="服务时间" prop="datePicker">
            <el-date-picker
              class="dialog-input"
              v-model="assignForm.datePicker"
              type="daterange"
              range-separator="~"
              @change="commonFun"
              :clearable="false"
              :picker-options="pickerOptions"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="指派客服" prop="serviceIds">
            <el-select
              class="dialog-input"
              v-model="assignForm.serviceIds"
              filterable
              multiple
              placeholder="选择客服"
            >
              <el-option
                v-for="item in serviceList"
                :key="item.id + ''"
                :label="item.nickName"
                :value="item.id + ''"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="common-screen-btn" @click="assignVisible = false"
          >取 消</el-button
        >
        <el-button
          class="common-screen-btn"
          type="primary"
          @click="
            () => {
              submitAssign('assignForm');
            }"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Breadcrumb from "../../../components/common/breadcrumb.vue";
import {
  subaccountListNew,
  addEditSubaccount,
  assignService,
  serviceUpload,
  deleteSubaccount
} from "../../../service/customer.js";
import { getRoleByType } from "../../../service/common.js";
import { tableColumn, DateTransform, Config } from "../../../utils/index.js";
export default {
  components: {
    Breadcrumb,
  },
  data() {
    return {
      tableColumn,
      BreadcrumbData: [
        { title: "首页", isLink: true, url: "/index" },
        { title: "接单中心", isLink: true, url: `/assign-order/receive-list?tabName=second&shopName=${this.$route.query.shopName}&from=order` },
        { title: "子账号管理", isLink: false },
      ],
      pagination: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100],
      },
      shopId: this.$route.query.shopId, // 店铺列表传值
      isAssign: this.$route.query.isAssign, // 判断是否是派单店铺
      tableRowId: "", // 客户id用于编辑/查看
      centerDialogVisible: false, // 新增修改弹窗
      assignVisible: false, // 指派分公司弹窗
      comPeopleList: [],
      assignForm: {}, // 指派分公司form表单
      rolesCompany: this.$sto.get(Config.constants.userInfo).roles_company, // 所属公司下拉
      assignRules: {
        serviceIds: [{ required: true, message: "请选择客服", trigger: "change" }],
        datePicker: [{ required: true, message: "请选择服务时间", trigger: "change" }],
      },
      serviceList: [], // 获取客服下拉
      btnLoading: false,
      params: {
        pageNum: 1,
        pageSize: 10,
      },
      ruleFormAdd: {
        shopId: this.$route.query.shopId,
      },
      rules: {
        subaccountName: [
          { required: true, message: "请填写子账号名称", trigger: "change" },
        ],
        subaccountPassword: [
          { required: true, message: "请填写子账号密码", trigger: "change" },
        ],
        datePicker: [{ required: true, message: "请选择服务时间", trigger: "change" }],
      },
      drawer: false, // 新增员工弹窗
      roleSelect: "",
      tableData: [],
      pickerOptions: {
        // 指派分公司的禁用时间范围
        disabledDate: (time) => {
          return (
            time.getTime() <
              new Date(this.$route.query.startTime).getTime() - 24 * 60 * 60 * 1000 ||
            time.getTime() > new Date(this.$route.query.endTime).getTime()
          );
        },
      },
    };
  },
  created() {
    // if (this.rolesCompany && this.rolesCompany.length == 1) {
    //   this.params.companyId = this.rolesCompany[0].id;
    // }
    this.subaccountListNew();
    this.getRoleByType(4);
    this.getRoleByType(2);
    
  },
  methods: {
    async deleteSubaccount(id) {
      await deleteSubaccount({}, id);
      this.$message.success("删除成功");
      this.subaccountListNew();
    },
    async serviceUpload(e) {
      this.btnLoading = true;
      let file = e.target.files[0];
      let format = file.name.split(".")[file.name.split(".").length - 1];
      if (format != "xls" && format != "xlsx") {
        this.$message.error("仅支持xls、xlsx格式文件");
        e.target.value = "";
        this.btnLoading = false;
        return;
      }
      let resData = (await serviceUpload({ file: file })).data;
      this.btnLoading = false;
      e.target.value = "";
      this.subaccountListNew();
      if (resData) {
        this.$message.error(resData);
      } else {
        this.$message.success("上传成功");
      }
    },
    downLoad(url, type) {
      // 下载交接文档
      if (type == 'fileUrl') {
        if (url) {
          window.location.href = url;
        } else {
          this.$message.error("暂无交接文档")
        }
      } else {
        window.location.href = Config.api.url + `/excel/serviceSubExport?companyId=${this.$route.query.companyId}&shopId=${this.$route.query.shopId}`
      }
      
    },
    submitAssign(formName) {
      // 指派提交
      let assignForm = { ...this.assignForm };
      if (assignForm.datePicker && assignForm.datePicker.length) {
        assignForm.startTime = DateTransform(assignForm.datePicker[0]);
        assignForm.endTime = DateTransform(assignForm.datePicker[1]);
        delete assignForm.datePicker
      }
      if (assignForm.serviceIds && typeof assignForm.serviceIds == "object") {
        assignForm.serviceIds = assignForm.serviceIds.join(",");
      }
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          await assignService(assignForm);
          this.$message.success("指派成功");
          this.subaccountListNew();
          this.assignVisible = false;
        } else {
          return false;
        }
      });
    },
    commonFun() {
      this.params.pageNum = 1;
      this.params.pageSize = 10;
      this.subaccountListNew();
    },
    handleSelectionChange(row) {
      console.log(row);
    },
    resetBtn() {
      this.params = {
        pageNum: 1,
        pageSize: 10,
      };
      this.subaccountListNew();
    },
    async getRoleByType(id) {
      // 获取客服下拉
      let resData = (await getRoleByType({}, id)).data;
      if (id == 2) {
        this.comPeopleList = resData;
      } else if (id == 4) {
        this.serviceList = resData;
      }
    },
    submitForm(formName) {
      // 新增修改提交
      let tableRowId = this.tableRowId;
      let ruleFormAdd = { ...this.ruleFormAdd };
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (tableRowId) {
            ruleFormAdd.id = tableRowId;
          }
          if (ruleFormAdd.datePicker && ruleFormAdd.datePicker.length) {
            ruleFormAdd.startTime = DateTransform(ruleFormAdd.datePicker[0]);
            ruleFormAdd.endTime = DateTransform(ruleFormAdd.datePicker[1]);
          }
          let { code } = await addEditSubaccount(ruleFormAdd);
          if ( code != 200 ) { return }
          if (tableRowId) {
            this.$message.success("修改成功");
          } else {
            this.$message.success("添加成功");
          }
          this.subaccountListNew();
          this.centerDialogVisible = false;
        } else {
          return false;
        }
      });
    },
    pageFun(e, type) {
      // 分页
      if (type == "pageSize") {
        this.params.pageSize = e;
      } else {
        this.params.pageNum = e;
      }
      this.subaccountListNew();
    },
    async subaccountListNew() {
      // 子账号列表
      
      let params = { ...this.params };
      if (params.datePicker && params.datePicker.length) {
        params.startTime = DateTransform(params.datePicker[0]);
        params.endTime = DateTransform(params.datePicker[1]);
        delete params.datePicker
      }
      this.loading = true;
      let { data } = await subaccountListNew({ ...params, id: this.shopId });
      // let { data } = await subaccountListNew({ ...params, id: this.shopId, companyId: this.$route.query.companyId });
      this.loading = false;
      this.pagination.total = data.total;
      this.pagination.pageSize = data.size;
      this.tableData = data.records;
      
    },
    assignService(row) {
      if (row) {
        this.assignForm = {
          subId: row.id,
          serviceIds: row.serviceId,
          datePicker:
            row.startTime && row.endTime
              ? [new Date(row.startTime), new Date(row.endTime)]
              : [
                  new Date(this.$route.query.startTime),
                  new Date(this.$route.query.endTime),
                ],
        };
        this.assignVisible = true;
      }
    },
    addEdit(row) {
      // 新增修改弹窗
      if (row) {
        this.tableRowId = row.id;
        this.ruleFormAdd = {
          shopId: this.$route.query.shopId,
          subaccountName: row.subaccountName,
          subaccountPassword: row.subaccountPassword,
          datePicker: [new Date(row.startTime), new Date(row.endTime)],
          note: row.note,
          id: row.id,
          phone: row.phone,
        };
      } else {
        this.tableRowId = "";
        this.ruleFormAdd = {
          shopId: this.$route.query.shopId,
          datePicker: [],
        };
        if (this.$refs["ruleForm"]) this.$refs["ruleForm"].resetFields();
      }
      this.centerDialogVisible = true;
    },
  },
};
</script>
<style lang="less" scoped>
.companySubaccount-container {
  text-align: left;
  padding: 16px;
  .iconPeople {
    width: 80px;
    height: 80px;
    color: #fff;
    background: #87d068;
    border-radius: 50%;
    font-size: 70px;
    line-height: 80px;
    text-align: center;
    margin-right: 16px;
  }
  .companySubaccount-detail {
    display: flex;
    padding: 10px 10px 24px;
    align-items: center;
    color: #000000a6;
    .detail-img {
      width: 82px;
      height: 82px;
      margin-right: 20px;
    }
    .detail-title {
      margin-bottom: 16px;
      font-size: 20px;
    }
    .detail-item {
      margin-right: 16px;
    }
  }
  .nameIcon {
    width: 32px;
    height: 32px;
    color: #f56a00;
    background-color: #fde3cf;
    border-radius: 50%;
    text-align: center;
    line-height: 32px;
  }
}
.dialog-input {
  width: 320px !important;
}
</style>
